/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import Link from 'gatsby-link'
import Icon from './../Icon'
import Wrapper from './../Wrapper'
import styles from './styles'

type Props = {
  children: React$Node,
  className?: string,
  enableMinimalistNav?: boolean,
  brandText?: string,
}

type State = {|
  menuIsOpen?: boolean,
|}

export default class Header extends React.Component<Props, State> {
  static defaultProps = {
    brandText:
      "Découvrez aujourd'hui le logiciel de caisse tout-en-un pour centraliser vos activités",
  }

  state = { menuIsOpen: false }

  handleToggleMenu = () => {
    this.setState(prevState => ({
      menuIsOpen: !prevState.menuIsOpen,
    }))
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return this.state.menuIsOpen !== nextState.menuIsOpen
  }

  render() {
    const { children, className, enableMinimalistNav, brandText } = this.props
    const { menuIsOpen } = this.state
    if (!children) return null
    const [logo, navBar, actions] = React.Children.toArray(children)
    return (
      <div className={merge(css(styles.container), className)}>
        <Wrapper className={css(styles.wrapper)}>
          <div className={css(styles.heading)}>
            <Link to="/" className={css(styles.logo)}>
              {logo}
            </Link>
            {!enableMinimalistNav && (
              <div className={css(styles.flex)}>
                <a className={css(styles.contact)} href="tel:0183641233">
                  <Icon name="phone" size={12} className={css(styles.icon)} /> 01 89 71 92 70
                </a>
                <button className={css(styles.burger)} onClick={this.handleToggleMenu}>
                  <Icon name="menu" />
                </button>
              </div>
            )}
            {enableMinimalistNav && <div className={css(styles.minimalistText)}>{brandText}</div>}
          </div>
          <div className={css(styles.menu, menuIsOpen && styles.openMenu)}>
            <div className={css(styles.navBar)}>{navBar}</div>
            <div className={css(styles.actions)}>{actions}</div>
          </div>
        </Wrapper>
      </div>
    )
  }
}
